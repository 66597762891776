import React from 'react'
import { Section, AboutContent, TechnologiesList, Li } from './About.styled'

const About = () => {
  return (
    <Section>
      <h2 id="about">About</h2>
      <AboutContent>
        <p>
          Hello! My name is Cornelius and I like to create things that live on
          the internet. My interest in web development started in 2014 when I
          got a taste of how websites are structured with HTML and styled with
          CSS in high school – it turns out that hacking together a simple
          website taught me a lot about what career I wanted to procure!
        </p>
        <p>
          Fast forward to today. Where i have a certificate of apprenticeship in
          ICT, finished a bachelor's degree in web development, a summer job as
          a software developer, and now starting as a full-time front-end
          developer at Nevion.
        </p>
        <p>
          I have a passion for backend development, and have mainly used Node.js, Express and MongoDB. 
          My goal is to learn other backend languages to improve my skills as an backend developer. 
          I have also picked up an interest for sustainable development and would like to promote awearness around this topic. 
        </p>
        <p>Here are a few technologies i have been working with recently:</p>
        <TechnologiesList>
          <Li>JavaScript</Li>
          <Li>MongoDB</Li>
          <Li>Express</Li>
          <Li>React</Li>
          <Li>Node.js</Li>
          <Li>TypeScript</Li>
          <Li>ReScript</Li>
          <Li>Lua</Li>
        </TechnologiesList>
      </AboutContent>
    </Section>
  )
}

export default About
