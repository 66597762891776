import React from 'react'
import {
  Img,
  Section,
  Title,
  Subtitle,
  SocialContainer,
  SocialIcons,
  HeroContainer,
} from './Hero.styles'
import { AiFillGithub, AiFillMail, AiFillLinkedin } from 'react-icons/ai'
import portrait from '../../../images/portrait.jpeg'
const Hero = () => (
  <Section>
    <HeroContainer id='home'>
      <Img src={portrait} alt='Cornelius smiling' />
      <Title>
        Cornelius Sandmæl
      </Title>
      <Subtitle> Student & Software Developer</Subtitle>
    </HeroContainer>
    <SocialContainer>
      <SocialIcons href='https://github.com/sandmal/' target='_blank'>
        <AiFillGithub />
        <p>GitHub</p>
      </SocialIcons>
      <SocialIcons
        href='https://www.linkedin.com/in/corneliussandmael/'
        target='_blank'>
        <AiFillLinkedin />
        <p>LinkedIn</p>
      </SocialIcons>
      <SocialIcons
        href='mailto:cornelius@sandmal.com?subject=Contact from portfolio'
        target='_blank'>
        <AiFillMail />
        <p>E-mail</p>
      </SocialIcons>
    </SocialContainer>
  </Section>
)

export default Hero
