import styled from 'styled-components'

export const Section = styled.section`
  max-width: 100%;
  margin: 100px 0;
  h2 {
    text-align: center;
    scroll-margin-top: 10rem;
    margin: 1em;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  scroll-margin-top: 12vh;
  @media ${(props) => props.theme.breakpoints.xl} {
    margin: 200px 0;
  }
`
export const ProjectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-flow: row wrap;
  margin: 0.5em 0;
  @media ${(props) => props.theme.breakpoints.xl} {
    gap: 3em;
  }
`
