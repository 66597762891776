import React from 'react'
import {
  ProjectHeader,
  ProjectCardContainer,
  ProjectInformation,
  ProjectLink,
  DescriptionContainer,
} from './ProjectCard.styled'

import { FaLongArrowAltRight } from 'react-icons/fa'

import { GatsbyImage } from 'gatsby-plugin-image'

const ProjectCard = (props) => {
  const { title, description, slug } = props.project.frontmatter

  const image = props.project.frontmatter.img.childImageSharp.gatsbyImageData
  return (
    <>
      <ProjectCardContainer>
        <ProjectInformation>
          <ProjectHeader>
            <h3>{title}</h3>
          </ProjectHeader>
          <GatsbyImage image={image} alt='hey' />
          <DescriptionContainer>
            <p>Description</p>
            <p>{description}</p>
          </DescriptionContainer>
        </ProjectInformation>
        <ProjectLink to={`/projects/${slug}`}>
          Read more <FaLongArrowAltRight />
        </ProjectLink>
      </ProjectCardContainer>
    </>
  )
}

export default ProjectCard
