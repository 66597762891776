import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    margin: 1.5em;
    scroll-margin-top: 10rem;
  }
`

export const AboutContent = styled.div`
  padding: 0.2em 1.2em;
  border-radius: 0.7rem;
  line-height: 25px;
  background-color: rgb(${(props) => props.theme.global.bg});

  ul {
    margin: -1em 0 1em 0;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    max-width: 60%;
  }
`

export const TechnologiesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 0 10px;
  padding: 10px;
  margin: 20px 0 0;
  overflow: hidded;
  list-style: none;
`

export const Li = styled.li`
  &:before {
    content: '▹';
    color: rgb(${(props) => props.theme.global.color});
    margin-right: 5px;
  }
`
