import styled from "styled-components"

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: calc(100vh - 5rem);
`

export const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 20rem;

`

export const Img = styled.img`
  border-radius: 100%;
  max-width: 100%;
`
export const Title = styled.h1`
  font-size: 2.2rem;
  margin-top: 1em;
`
export const Subtitle = styled.h2`
  font-size: 1.1rem;
  margin: 0.6em;
`
export const Button = styled.button`
  margin-top: 2em;
`

export const SocialContainer = styled.div`
  margin-top: 2em;
`

// Social Icons
export const SocialIcons = styled.a`
  transition: 0.3s ease;
  color: rgb(${props => props.theme.global.link});
  max-width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0.2em;
    font-size: 1.1rem;
  }
  svg {
    max-width: 100%;
    margin: 0 0.8em;
    flex-direction: row;
    font-size: 2em;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }

    path {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }
`
