import React from 'react'
import Projects from './Projects/Projects'
import About from './About/About'

const Main = () => {
  return (
    <>
      <About />
      <Projects />
    </>
  )
}

export default Main
