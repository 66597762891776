import React from 'react'
import ProjectCard from './ProjectCard'
// import projects from '../../../API/Projects.json'
import { ProjectContainer, Section } from './Projects.styled'
import { graphql, StaticQuery } from 'gatsby'

const Projects = () => {
  return (
    <StaticQuery
      query={graphql`
        query Projects {
          allMarkdownRemark {
            nodes {
              frontmatter {
                description
                slug
                title
                stack
                link
                img {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Section id='projects'>
          <h2>Projects</h2>
          <ProjectContainer>
            {data.allMarkdownRemark.nodes.map((project) => (
              <ProjectCard project={project} key={project.frontmatter.title} />
            ))}
          </ProjectContainer>
        </Section>
      )}
    />
  )
}

export default Projects
