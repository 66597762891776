import React from 'react'
import Hero from '../components/Header/Hero/Hero'
import Main from '../components/Main/Main'
import Seo from '../components/Seo/Seo'

const IndexPage = () => {
  return (
    <>
      <Seo title='Home' />
      <Hero />
      <Main />
    </>
  )
}

export default IndexPage
